/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Images
import phone from 'img/phone.svg'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import HeroDefault from 'components/elements/HeroDefault'
import TitleDefault from 'components/elements/TitleDefault'
import ContentDefault from 'components/elements/ContentDefault'
import ButtonDefault from 'components/elements/ButtonDefault'
import Testimonials from 'components/Testimonials'
import Modal from 'components/elements/Modal'
import GravityForm from 'components/GravityForm'
import DownloadDuoModal from 'components/elements/DownloadDuoModal'


// Images
import CheckIcon from 'img/icons/check-icon.svg'

const Container = styled.div`
  position: relative;
`

const BenefitBox = styled.div`
  box-shadow: 0px 0px 4px #00000080;
  border-bottom-left-radius: 20px;
`
const BenefitHeader = styled.div`
  background-color: ${(props) => props.theme.color.contrast};
`
const BenefitTitle = styled(TitleDefault)`
  font-size: ${(props) => props.theme.font.size.xl};
  color: ${(props) => props.theme.color.text.light};
  max-width: none;
`

const Title = styled.p`
  font-weight: ${(props) => props.theme.font.weight.l};
  font-size: ${(props) => props.theme.font.size.sm};
`

const BenefitContent = styled.div`
  position: relative;
`

const Content = styled(ContentDefault)`
  font-size: ${(props) => props.theme.font.size.sm};
  line-height: ${(props) => props.theme.font.size.xm};
`

const DuoPurchase = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.color.secondary};
`

const Principel = styled.div`
  position: relative;

  &:first-child {
    @media (min-width: 992px) {
      margin-bottom: 3rem;
      padding-bottom: 3rem;

      &::before {
        content: '';
        height: 0;
        border: 2px solid #979797;
        display: block;
        width: 89px;
        transform: rotate(-40deg);
        position: absolute;
        left: -110px;
        top: 70px;
      }
    }
  }

  &:last-child {
    @media (min-width: 991px) {
      &::before {
        content: '';
        height: 0;
        border: 2px solid #979797;
        display: block;
        width: 89px;
        transform: rotate(40deg);
        position: absolute;
        left: -110px;
        top: 70px;
      }
    }
  }
`

const PrincipelTitle = styled.div`
  position: relative;
  font-family: ${(props) => props.theme.font.family.secondaryAlt};
  font-size: ${(props) => props.theme.font.size.xm};
  color: ${(props) => props.theme.color.text.light};
  background-color: ${(props) => props.theme.color.text.main};
`

const PrincipelContent = styled.div`
  background-color: ${(props) => props.theme.color.light};
`

const DuoText = styled(ContentDefault)`
  & h3 {
    font-family: ${(props) => props.theme.font.family.secondaryAlt};
    font-size: ${(props) => props.theme.font.size.ml};
    color: ${(props) => props.theme.color.text.secondary};
    margin-bottom: 1.5rem;
  }
  & p {
    font-weight: ${(props) => props.theme.font.weight.l};
    color: ${(props) => props.theme.color.text.secondary};
  }
`

const DuoVideo = styled.iframe`
  box-shadow: 0px 0px 4px #00000080;
`

const DuoPurchaseIcon = styled.img`
  @media (min-width: 992px) {
    width: 300px;
  }

  @media (max-width: 991px) {
    width: 200px;
  }
`
const Contact = styled.div``

const FormContainer = styled.div`
  box-shadow: 0px 0px 4px #00000080;
  max-width: 600px;
  border-bottom-left-radius: 20px;
`

const FormTitle = styled.div`
  height: 50px;
  max-width: 600px;
  padding-left: 100px;
  padding-right: 100px;

  h2 {
    font-size: ${props => props.theme.font.size.ml};

    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size.xm};
    }
  }

  @media screen and (max-width: 991px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const FormWrapper = styled.div`
  padding-left: 100px;
  padding-right: 100px;

  @media screen and (max-width: 991px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const Info = styled.div`
  width: 320px;

  @media (max-width: 991px){
    width: 560px;
  }

  a {
    color: ${props => props.theme.color.text.light};
    &:hover {
      text-decoration: underline;
    }
  }
`

const Icon = styled.img`
  width: 23px;
  height: 23px;
`

const StyledButtonDefault = styled(ButtonDefault)`
  transition: transform 0.25s;

  &:hover {
    transform: scale(1.05);
  }
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      acf {
        bannerImage: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              meta: fluid(maxWidth: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
        bannerTitle: banner_title
        bannerButtonText: banner_button_text
        bannerButtonLink: banner_button_link

        intro {
          title
          description
          button {
            text
            url
          }
        }

        downloadModal: download_modal {
          description
          button
        }

        benefits {
          title
          benefit {
            title
            description
          }
          button {
            text
            url
          }
        }

        formSection: form_section {
          title
          ctaTitle: cta_title
        }

        testimonial {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          quote
        }

        duoPurchase: duo_purchase {
          video
          title
          principels {
            icon {
              localFile {
                publicURL
              }
            }
            principel {
              title
              description
            }
          }
          description
        }
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }

    contact: wordpressPage(wordpress_id: { eq: 9 }) {
      acf {
        phonenumber
        email_address
        streetname
        housenumber
        zipcode
        city
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoastMeta, acf },
    contact,
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={{ meta: yoastMeta }}
        pathname={path}
        image={acf.bannerImage.localFile.childImageSharp.fluid.src}
        article
      />
      <HeroDefault
        image={acf.bannerImage}
        title={acf.bannerTitle}
        linkWithIcon={{
          link: {
            text: acf.bannerButtonText,
            url: acf.bannerButtonLink,
          },
        }}
      />
      <section className="mt-5">
        <Container className="container">
          <div className="row py-5">
            <div className="col-lg-6">
              <TitleDefault h1 className="text-break">
                {acf.intro.title}
              </TitleDefault>
            </div>
            <div className="col-lg-6">
              <ContentDefault content={acf.intro.description} />
              <ButtonDefault className="mt-2 mb-5" to={acf.intro.button.url}>
                  {acf.intro.button.text}
              </ButtonDefault>

              <ContentDefault content={acf.downloadModal.description} />
              <DownloadDuoModal buttonText={acf.downloadModal.button} custom />
                
            </div>
          </div>
        </Container>
      </section>
      {acf.duoPurchase && acf.duoPurchase.title && (
        <>
          <DuoPurchase>
            <Container className="container mt-5 py-5">
              <div className="row">
                <TitleDefault>{acf.duoPurchase.title}</TitleDefault>
              </div>
              <div className="row align-items-center mb-lg-5 pb-5">
                <div className="col-lg-5 text-lg-right text-center py-4 py-lg-0 mb-3 mb-lg-0">
                  <DuoPurchaseIcon
                    src={acf.duoPurchase.principels.icon.localFile.publicURL}
                    alt=""
                  />
                </div>
                <div className="col-lg-7 d-flex flex-wrap justify-content-center">
                  {acf.duoPurchase.principels.principel.map(
                    ({ title, description }) => (
                      <>
                        <Principel className="col-lg-7 pb-4 pb-lg-0">
                          <PrincipelTitle className="py-2 text-center">
                            {title}
                          </PrincipelTitle>
                          <PrincipelContent className="py-4 text-center">
                            {description}
                          </PrincipelContent>
                        </Principel>
                      </>
                    )
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-10 mx-auto">
                  <DuoText content={acf.duoPurchase.description} />
                </div>
              </div>
              {acf.duoPurchase.video && (
                <div className="row mt-4">
                  <div className="col-md-3" />
                  <div className="col-md-6">
                    <DuoVideo
                      title={acf.duoPurchase.title}
                      width="560"
                      height="315"
                      src={acf.duoPurchase.video}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullscreen
                    />
                  </div>
                </div>
              )}
            </Container>
          </DuoPurchase>
          <section className="my-5 pt-3 pb-5">
            <Container className="container">
              <div className="row px-3 px-lg-0">
                <BenefitBox className="col-lg-10 mx-auto px-0">
                  <BenefitHeader className="col py-3">
                    <BenefitTitle className="text-center mb-0">
                      {acf.benefits.title}
                    </BenefitTitle>
                  </BenefitHeader>
                  <BenefitContent className="col-lg-9 mx-auto p-5">
                    {acf.benefits.benefit.map(({ title, description }) => (
                      <div className="d-flex">
                        <div className="mr-4">
                          <img src={CheckIcon} alt="" />
                        </div>
                        <div>
                          <Title className="mb-0">{title}</Title>
                          <Content content={description} />
                        </div>
                      </div>
                    ))}
                  </BenefitContent>
                  <div className="col-lg-9 mx-auto text-center mb-n3">
                    <Modal buttonText={acf.benefits.button.text}>
                      {acf.benefits.button.url}
                    </Modal>
                  </div>
                </BenefitBox>
              </div>
            </Container>
          </section>
        </>
      )}

      <Contact id="contact" className="color-background-soft py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 d-lg-block d-flex justify-content-center py-lg-0 py-4e">
              <FormContainer className="d-flex justify-content-center flex-wrap color-background-light">
                <FormTitle className="w-100 d-flex align-items-center color-background-contrast">
                  <h2 className="mb-0 font-family-secondary-alt color-text-light">{acf.formSection.title}</h2>
                </FormTitle>
                <FormWrapper className="w-100 pb-5 pt-4">
                  <GravityForm id={3} />
                </FormWrapper>
              </FormContainer>
            </div>
            <div className="col-lg-4 d-lg-block d-flex justify-content-center py-lg-0 py-4">
              <Info className="color-background-dark px-5 pt-4 pb-5">
                <h2 className="mb-0 mt-2 font-size-xm font-family-secondary-alt color-text-light">{acf.formSection.ctaTitle}</h2>

                <div className="d-flex justify-content-center py-4">
                  <StyledButtonDefault>
                    <Icon className="mr-2" src={phone} alt="" />
                    <p className="font-size-m font-family-secondary-alt mb-0"><a href={`tel:${contact.acf.phonenumber}`}>{contact.acf.phonenumber}</a></p>
                  </StyledButtonDefault>
                </div>

                <h2 className="font-size-xsm color-text-light font-family-secondary-alt">WelBijWim</h2>
                <p className="font-size-sm color-text-light mb-0">{`${contact.acf.streetname} ${contact.acf.housenumber}`}</p>
                <p className="font-size-sm color-text-light mb-0">{contact.acf.zipcode}</p>
                <p className="font-size-sm color-text-light mb-3">{contact.acf.city}</p>
                <a href={`mailto:${contact.acf.email}`} className="font-size-sm color-text-light">{contact.acf.email}</a>
              </Info>
            </div>
          </div>
        </div>
      </Contact>

      <section>
        <Testimonials
          id={[acf.testimonial.quote]}
          image={acf.testimonial.image.localFile.childImageSharp.fluid}
        />
      </section>
    </Layout>
  )
}

export default PageTemplate
